import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import ReviewImg from 'images/blog-images/2020-in-review.png';
import ReviewImgFeatured from 'images/featured-image/2020-in-review-featured.png';
import AmazonPositiveReviews from 'images/blog-images/amazon-positive-reviews.png';
import Top100Searches from 'images/blog-images/top-100-searches.png';
import AmazonNewSeller from 'images/blog-images/amazon-new-sellers-percentage.png';
import LifetimeActiveSellers from 'images/blog-images/lifetime-active-sellers.png';
import ShareOfAmazon from 'images/blog-images/share-of-amazon.png';
import GoogleShopping from 'images/blog-images/google-shopping-winners-losers.png';
import ShareofAmazonThirdParty from 'images/blog-images/share-of-amazon-third-party-sellers.png';
import AmazonWorldwideGmv from 'images/blog-images/estimated-worldwide-amazon-gmv.png';


interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ReviewImgBG {
      file(relativePath: { eq: "2020-in-review.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="2021 uk ecommerce reforms"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        title={intl.formatMessage({ id: "blog-post-meta-title-2020-in-review" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              content: intl.formatMessage({ id: "blog-post-meta-title-2020-in-review" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: ReviewImgFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="2020-in-review-ultra-full-cross-border-ecommerce-market-analysis"
        title={intl.formatMessage({ id: "2020-in-review-head-title" })}
        date="2020-12-30"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              2020 is the best year for the e-commerce market in more than a decade. The growth of eCommerce has undergone rapid changes, and online eCommerce platforms have captured most of them. In general, this year is also the most successful year for sellers and brands that conduct transactions through these eCommerce platforms.
            </p>

            <p>
              According to the market overview and hot trends of cross-border eCommerce platforms in 2020 released by <a href="https://www.marketplacepulse.com/" target="_blank" rel="noreferrer">Marketplace Pulse</a> recently, we can know the following important data (partially listed):
            </p>

            <Image src={ReviewImg} className="" alt="2020 in Review"/>

            <h5>
              <strong>
                Overview of the 7 mainstream cross-border eCommerce platforms
              </strong>
            </h5>

            <p>
              With the vigorous development of e-commerce during the epidemic, to some extent, Amazon, Etsy, Wal-Mart, and Target became the four winners. Due to different reasons, their respective sales have increased, the number of sellers has increased, and the market share has also been expanding. Among them, Target has the fastest growth rate, but it's only invited seller base is difficult to compare with larger platforms.
            </p>

            <p>
              Google Shopping, Wish and eBay performed poorly. eBay has achieved growth for two consecutive quarters, but it is unlikely to maintain this momentum. Because Wish relies on Chinese sellers, the delivery time is slow, and sales growth in the third quarter is also lagging behind. Google Shopping hasn’t made any obvious moves in trying to become an e-commerce channel. It remains a bystander, powering the ads at the top of the funnel.
            </p>

            <Image src={GoogleShopping} alt="google shopping winners and losers" />

            <h5>
              <strong>
                Amazon Gross Merchandise Volume (GMV)
              </strong>
            </h5>

            <p>
              In 2020, sellers on Amazon sold $295 billion worth of goods, and as a retailer, Amazon's sales were $180 billion. According to the preliminary estimate disclosed by Amazon, the total merchandise turnover of Amazon this year is 475 billion US dollars.
            </p>

            <Image src={AmazonWorldwideGmv} alt="estimated worldwide amazon gmv" />

            <p>
              The sales of third-party sellers increased by 95 billion U.S. dollars in a year, up from 200 billion U.S. dollars in 2019. GMV increased by 42%, first-party sales increased by 35%, and third-party sales increased by 47%. 62% of Amazon's global GMV is determined by third-party platforms, which is higher than 60% in 2019 and 58% in 2018.
            </p>

            <Image src={ShareofAmazonThirdParty} alt="share of amazon gmv by third party sellers"/>        

            <h5>
              <strong>
                COVID Impact
              </strong>
            </h5>

           <p>
              Demand for daily necessities such as toilet paper, hand sanitizer, paper towels, N95 masks and thermometers peaked in March. By the end of this year, the number of shoppers looking for these products had dropped significantly. In March of this year, 46% of the 100 most searched keywords on Amazon were related to "necessities." This figure dropped to only 3% in December.
            </p>

            <Image src={Top100Searches} alt="share of top 100 searches related to covid-19"/>

            <p>
              In May of this year, Amazon encountered the highest negative seller reviews in its history. Seller reviews are the best indicator of the mood of Amazon shoppers because they can collect up to 10 million reviews a month. In the 85 days from February 29 to May 24, the share of positive comments on Amazon's global site fell from 92.5% to 88.7%. Then, it took almost the same length of time to recover until August 12.
            </p>

            <p>
              Consumers have more and more negative experiences, partly due to Amazon’s delivery difficulties, causing sellers to sell out the inventory of Amazon (FBA) operation centers, thereby reducing the types of products supported by Prime, the timeline of soaring negative reviews and enabling Prime The reduction of sellers matches. From the end of February to the beginning of August, sellers completed more sales than usual.
            </p>

            <Image src={AmazonPositiveReviews} alt="positive seller reviews on amazon marketplace"/>

            <h5>
              <strong>
                Small Sellers vs. Big Sellers
              </strong>
            </h5>

            <p>
              In the four largest markets (the United States, the United Kingdom, Germany and Japan), more than half of the sales are completed by sellers who joined in 2017 or earlier.
            </p>

            <Image src={ShareOfAmazon} alt="share of amazon gmv by seller cohort"/>

            <p>
              Active sellers usually stay for many years. For example, among the top 10,000 sellers in 2015, 84% of sellers were still active sellers in their third year on Amazon. 89% of Top sellers remained active after two years, and 95% of sellers remained active after one year. These percentages of Top sellers in 2015, 2016, 2017, 2018, and 2019 remain unchanged.
            </p>

            <Image src={LifetimeActiveSellers} alt="lifetime active sellers by top sellers cohort"/>

            <h5>
              <strong>
                China Sellers
              </strong>
            </h5>

            <p>
              Since March, as the economy has resumed growth, more than half (more than 50%) of new sellers on Amazon in the US are from China. This compares to 36% in 2019. Every February, due to the Lunar New Year, the number of new sellers in China is the least.
            </p>

            <Image src={AmazonNewSeller} alt="percentage of new amazon.com sellers based in china"/>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>{intl.formatMessage({ id: "more-from-shipkoo-text" })}</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;